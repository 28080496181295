import React, { useState } from 'react';
import { Layout, Seo } from 'components';
import { Row, Col } from "react-grid-system";
import Hero from "components/hero";
import { TypeA } from "components/callouts";
import VideoModal from "components/video-modal";
import homeHeroImg from "images/home/homepage_hero_desktop.webp";
import homeHeroMobileImg from "images/home/homepage_hero_mobile.webp";
import prepTimeIcon from "images/icons/icon-prep-time.svg";
import safetyIcon from "images/icons/icon-safety.svg";
import inventoryIcon from "images/icons/icon-inventory.svg";
import "./index.scss";
// import endoProductVideo from '../videos/endo-rtu-products-video.mp4'
import endoProductVideoThumbnail from 'images/endo-product-video-thumbnail.webp'
import AllProducts from '../codes/components/all-products/indexs';

const Reference = (
  <>
    <p>
      <strong>References: 1. </strong>American Society of Health-System Pharmacists. ASHP guidelines on preventing medication errors in hospitals. <i>Am J Health-Syst Pharm.</i> 2018;75(19):1493-1517. <strong>2.</strong> U.S. Food & Drug Administration. New Drug Approval for Epinephrine: NDA 215875. April 21, 2023. <strong>3.</strong> Data on file. DOF-DX-01. Endo USA, Inc.; July 26, 2023. <strong>4.</strong> Data on file. DOF-EH-01. Endo USA, Inc.; July 31, 2023. <strong>5.</strong> Data on file. DOF-NHC-01. Endo USA, Inc.; November 13, 2024. <strong>6.</strong> Data on file. DOF-PRVD-01. Endo USA, Inc.; July 20, 2023. <strong>7.</strong> Healthcare Distribution Alliance. Standard pharmaceutical product and medical device information: Bivalirudin Injection. July 10, 2023. <strong>8.</strong> Data on file. DOF-VS-02. Endo USA, Inc.; September 20, 2023. <strong>9.</strong> Adrenalin<sup>®</sup> (epinephrine in sodium chloride injection). Prescribing Information. Endo USA, Inc. <strong>10.</strong> Bivalirudin Injection. Prescribing Information. Endo USA, Inc. <strong>11.</strong> Dexmedetomidine Hydrochloride Injection. Prescribing Information. Endo USA, Inc. <strong>12.</strong> Ephedrine Sulfate Injection. Prescribing Information. Endo USA, Inc. <strong>13.</strong> Naloxone Hydrochloride Injection. Prescribing Information. Endo USA, Inc. <strong>14.</strong> PREVDUO<sup>®</sup>. Prescribing Information. Endo USA, Inc. <strong>15.</strong> Vasostrict<sup>®</sup>. Prescribing Information. Endo USA, Inc. <strong>16.</strong> The United States Pharmacopeial Convention. USP compounding standards and beyond-use dates. https://go.usp.org/2022_Revisions_795_797. Accessed October 10, 2024.
    </p>
  </>
)

function IndexPage() {
  const [showModal, setShowModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  const handlePlayVideo = () => {
    // setVideoUrl(
    // 	'https://player.vimeo.com/766882208',
    // );
    setShowModal(true);
  };

  const handleCloseVideo = () => {
    setVideoUrl('');
    setShowModal(false);
  };
  return (
    <Layout
      noShowStickyIsi
      pageClass="home"
      reference={Reference}
      hero={
        <Hero
          className="hero-home"
          heroImg={homeHeroImg}
          heroImgWidth='3098'
          heroImgHeight='1990'
          heroImgAlt="blurred image of doctors making preparations in surgical room"
          heroMobileImg={homeHeroMobileImg}
          heroMobImgWidth='824'
          heroMobImgHeight='530'
          heroMobileImgAlt="blurred image of doctors making preparations in surgical room"
        >
          <p>Reduce preparation time, minimize preparation error,<sup>1</sup> extend shelf life<sup>2-8</sup>*</p>
        </Hero>
      }
    >
      <Seo pageTitle="TruDelivery&reg; ready-to-use (RTU) products by Endo USA, Inc." pageDescription="TruDelivery&reg; ready-to-use (RTU) products by Endo USA, Inc. TruDelivery delivers without missing, compounding, or diluting" />

      <section className="first">
        <div className="centered-content">
          <Row>
            <Col xs={12}>
              <div className="block-title">
                <h1>TruDelivery<sup>&reg;</sup> ready-to-use (RTU) products by Endo</h1>
                <p>The TruDelivery<sup>&reg;</sup> RTU portfolio helps meet the real-world challenges of hospital pharmacies—and is always ready when you are. <br className="show-desktop" />It’s about time.</p>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className="block-better-prepared">
        <div className="centered-content">
          <Row gutterWidth={20}>
            <Col xs={12}>
              <div className="block-title">
                <h2 className="txt-center">Simply better prepared</h2>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <TypeA
                imgRef={prepTimeIcon}
                imgAlt="Reduce preparation time"
                imgHeight='124'
                imgWidth='124'
                ctaTitle="Reduce <br> preparation time"
                ctaText="TruDelivery<sup>&reg;</sup> RTU products are premixed and ready to administer without additional preparation.<sup>9-15</sup>"
                className="first"
              />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <TypeA
                imgRef={safetyIcon}
                imgAlt="Minimize preparation error"
                imgHeight='124'
                imgWidth='124'
                ctaTitle="Minimize <br> preparation error<sup>1</sup>"
                ctaText="TruDelivery<sup>&reg;</sup> RTU products require no compounding, diluting, or mixing,<sup>9-15</sup> which reduces the risk of medication error.<sup>1</sup>"
              />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <TypeA
                imgRef={inventoryIcon}
                imgAlt="Streamline inventory management"
                imgHeight='124'
                imgWidth='124'
                ctaTitle="Streamline inventory management"
                ctaText="Available through wholesalers and compatible with most automated dispensing machines, TruDelivery<sup>&reg;</sup> RTU products offer a longer, more stable shelf life compared to compounded products.<sup>2-8,16</sup>*"
                className="last"
              />
            </Col>
          </Row>

          <div className="footnotes">
            <div className="footnotes-wrap">
              <span className='symbol'>*</span>
              <p className="footnotes-symbols">For Category 3 compounded sterile preparations (CSP), the maximum beyond-use date (BUD) is 90 days at controlled room temperature (CRT), 120 days in refrigerator, and 180 days in freezer<sup>16</sup> versus shelf life of 2 years at CRT for Adrenalin<sup>®</sup>, Dexmedetomidine, Ephedrine, Naloxone, and PREVDUO<sup>®2-6</sup>; 12 months at CRT and 2 years in refrigerator for Vasostrict<sup>®8</sup>; 72 hours at CRT and 18 months in refrigerator for Bivalirudin.<sup>7,10</sup></p>
            </div>
          </div>
        </div>
      </section>

      <section className="block-explore-portfolio gray-bg">
        <div className="centered-content">
          <div className="block-title">
            <h2 className="txt-blue txt-center">Explore TruDelivery<sup>&reg;</sup> RTU portfolio</h2>
          </div>

          <AllProducts />
        </div>
      </section>

      <section className='block-video'>
        <div className="centered-content">
          <Row>
            <Col xs={12}>
              <VideoModal
                closeModal={handleCloseVideo}
                open={showModal}
                url="https://endo.wistia.com/medias/4y3kxuphdr"
                playing={true}
              // videoThumbnail={endoProductVideoThumbnail}
              />

              <div className="video-cout">
                <button className="txt-center" onClick={handlePlayVideo}>
                  See how hospital pharmacies may benefit
                </button>
                <div className="video-thumb-wrapper" onClick={handlePlayVideo}>
                  <img src={endoProductVideoThumbnail} alt="TruDelivery Endo RTU Product Video Thumbnail" height={1036} width={1844} loading='lazy' />
                  <div className="btn-play"></div>
                </div>
              </div>

            </Col>
          </Row>
        </div>
      </section>
    </Layout >
  );
}

export default IndexPage;
