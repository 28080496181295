import React, { useEffect, useRef } from 'react';
import videoFile from '../../videos/endo-tru-products-video.mp4';

const IntroAnimation = ({ onComplete }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 1.5;
    }
  }, []);

  return (
    <div
      className="intro-animation-wrapper"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 9999,
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <video
        ref={videoRef}
        autoPlay
        muted
        playsInline
        onEnded={onComplete}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'contain'
        }}
      >
        <source src={videoFile} type="video/mp4" />
      </video>
    </div>
  );
};

export default IntroAnimation;