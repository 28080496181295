const metaData = {
  SITE_NAME: `TruDelivery® ready-to-use (RTU) products by Endo USA, Inc.`,
  SITE_DEFAULT_DESCRIPTION: `TruDelivery® ready-to-use (RTU) products by Endo USA, Inc. TruDelivery delivers without missing, compounding, or diluting`,
  SITE_DEFAULT_KEYWORDS: ['ready-to-use (RTU) products', 'RTU', 'Endo USA, Inc.', 'RTU portfolio', 'Adrenalin (epinephrine in 0.9% sodium chloride injection)', 'Bivalirudin Injection', 'Dexmedetomidine Hydrochloride Injection', 'ready-to-use bottles', 'prefilled syringes', 'preimixed vials', 'Ephinedrine Sulfate Injection', 'Naloxone Hydrochloride Injection, USP', 'PREVDUO®', 'Vasostrict®', 'optimized infentory management'],
  PATIENT_JOB_CODE: `XX-XXXX/March 2020`,
  HCP_JOB_CODE: `XX-XXXX/March 2020`,
  CURRENT_YEAR: new Date().getFullYear(),
};

/**
 * HEADER_TYPE : fixed or regular
 * ISI_OPTIONS["TYPE"] : sticky or regular
 */
const ISI_HEIGHT = 185;
const siteOptions = {
  INDICATIONS: ['hcp', 'patient'],
  HEADER_TYPE: 'fixed',
  STICKY_HEIGHT: ISI_HEIGHT,
  ISI_OPTIONS: {
    TYPE: 'sticky',
    STICKY_STYLES: {
      backgroundColor: '#ffffff',
      height: ISI_HEIGHT,
      width: '100%',
      boxShadow: '-1px -5px 15px 0px rgba(0,0,0,0.10)',
      paddingTop: 5,
    },
  },
};
const hcpModalInfo = {
  TITLE: `<h2>Intended for U.S. Residents Only<h2>`,
  BODY: `<h3>Welcome to BRANDX.com</h3><p>Are you a patient or a healthcare professional?</p>`,
  BUTTON_ONE_CAPTION: `I am a patient`,
  BUTTON_TWO_CAPTION: `I am a healthcare professional`,
  CLASS_NAME: `brandx-modal brandx-hcp-modal`,
};
const externalLinkInfo = {
  TITLE: ``,
  BODY: `<p>You are now leaving the TruDelivery<sup>&reg;</sup> website.</p><p>Would you like to proceed?</p>`,
  LINK_CLASS_NAME: `external-link`,
  OVERLAY_CLASS_NAME: `trudelivery-modal trudelivery-external-modal`,
};

const appConfigs = {
  metaData: metaData,
  siteOptions: siteOptions,
  hcpModalInfo: hcpModalInfo,
  externalLinkInfo: externalLinkInfo,
};

export default appConfigs;
