import React from "react";
import { Layout, Seo } from 'components';
import { Row, Col } from "react-grid-system";
import "./products.scss";
import AllProducts from "../../codes/components/all-products/indexs";

const Reference = (
    <>
        <p>
            <strong>References: 1.</strong> U.S. Food & Drug Administration. New Drug Approval for Epinephrine: NDA 215875. April 21, 2023. <strong>2.</strong> Data on file. DOF-DX-01. Endo USA, Inc.; July 26, 2023. <strong>3.</strong> Data on file. DOF-EH-01. Endo USA, Inc.; July 31, 2023. <strong>4.</strong> Data on file. DOF-NHC-01. Endo USA, Inc.; November 13, 2024. <strong>5.</strong> Data on file. DOF-PRVD-01. Endo USA, Inc.; July 20, 2023. <strong>6.</strong> Data on file. DOF-VS-02. Endo USA, Inc.; September 20, 2023. <br className="show-desktop" /><strong>7.</strong> Healthcare Distribution Alliance. Standard pharmaceutical product and medical device information: Bivalirudin Injection. July 10, 2023. <strong>8.</strong> Adrenalin<sup>&reg;</sup> (epinephrine in sodium chloride injection). Prescribing Information. Endo USA, Inc. <strong>9.</strong> Bivalirudin Injection. Prescribing Information. Endo USA, Inc. <strong>10.</strong> Dexmedetomidine Hydrochloride Injection. Prescribing Information. Endo USA, Inc. <strong>11.</strong> Ephedrine Sulfate Injection. Prescribing Information. Endo USA, Inc. <strong>12.</strong> PREVDUO<sup>&reg;</sup>. Prescribing Information. Endo USA, Inc. <strong>13.</strong> Naloxone Hydrochloride Injection. Prescribing Information. Endo USA, Inc. <strong>14.</strong> Vasostrict<sup>&reg;</sup>. Prescribing Information. Endo USA, Inc. <strong>15.</strong> The United States Pharmacopeial Convention. USP compounding standards and beyond-use dates. https://go.usp.org/2022_Revisions_795_797. Accessed October 10, 2024.
        </p>
    </>
)

function ProductsPage({ pageContext }) {
    return (
        <Layout
            noShowStickyIsi
            pageClass="products"
            reference={Reference}
            breadcrumbData={pageContext.breadcrumb}
        >
            <Seo pageTitle="Products" pageDescription="Explore the TruDelivery™ ready-to-use product portfolio" />
            <section className="first">
                <div className="centered-content">
                    <Row>
                        <Col xs={12}>
                            <h1 className="text-blue">Explore the growing portfolio of <br className="show-desktop" />TruDelivery<sup>&reg;</sup> ready-to-use (RTU) products</h1>
                            <h2>Ready when you need it, longer<sup>1-7</sup>*</h2>

                            <div className="block-content">
                                <p>TruDelivery<sup>&reg;</sup> RTU products require no compounding, diluting, or mixing<sup>8-14</sup> and provide extended dating compared with compounded products.<sup>1-7,15</sup>*</p>
                                <p>Most hospital pharmacy buyers can purchase TruDelivery<sup>&reg;</sup> RTU products with their routine daily wholesale orders. These TruDelivery<sup>&reg;</sup> RTU products are already available through your health system’s full-line wholesaler and on your group purchasing organization (GPO) contract.</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="block-product-details gray-bg">
                <div className="centered-content">
                    <div className="block-title">
                        <h2 className="txt-blue txt-center">Product Details</h2>
                    </div>

                    <AllProducts />

                </div>
            </section>

            <section className="block-footnotes">
                <div className="centered-content">
                    <div className="footnotes">
                        <div className="footnotes-wrap">
                            <span className='symbol'>*</span>
                            <p className="footnotes-symbols">For Category 3 compounded sterile preparations (CSP), the maximum beyond-use date (BUD) is 90 days at controlled room temperature (CRT), 120 days in refrigerator, and 180 days in freezer<sup>15</sup> versus shelf life of 2 years at CRT for Adrenalin<sup>&reg;</sup>, Dexmedetomidine, Ephedrine, Naloxone, and PREVDUO<sup>®1-5</sup>; 12 months at CRT and 2 years in refrigerator for Vasostrict<sup>®6</sup>; 72 hours at CRT and 18 months in refrigerator for Bivalirudin.<sup>7,9</sup></p>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default ProductsPage;
