import React, { useState, useEffect } from "react";
import Modal from 'react-awesome-modal';
import ReactPlayer from 'react-player';

function VideoModal({ url, videoThumbnail, ...props }) {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    // This will only run on the client side
    setIsClient(true);
  }, []);

  return (
    <div className="modal-container">
      <Modal visible={props.open} onClickAway={() => props.closeModal()}>
        <div className="video-container">
          <div className="video-header">
            <button className="close" onClick={() => props.closeModal()}>
              ×
            </button>
          </div>
          <div className="video-content">
            {isClient ? (
              <ReactPlayer
                className="react-player"
                url={url}
                controls={true}
                playing={props.open}
              />
            ) : (
              // Server-side rendering fallback
              <img src={videoThumbnail} alt="Video thumbnail" className="video-thumbnail" />
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default VideoModal;