import React from 'react';
import { Layout, Seo } from 'components';
import { Row, Col } from "react-grid-system";
import ProductHero from "components/product-hero";
import SlideContent from "components/slide-content";
import productData from "components/product-data";
import Slides from "components/product-data/slides";
import imgEphedrineSulfate from "images/products/img-ephedrine-sulfate-injection.webp";
import './products.scss';
import AllProducts from '../../codes/components/all-products/indexs';

const Reference = (
    <>
        <p>
            <strong>References: 1.</strong> Ephedrine Sulfate Injection. Prescribing Information. Endo USA, Inc. <strong>2.</strong> The United States Pharmacopeial Convention. USP compounding standards and beyond-use dates. https://go.usp.org/2022_Revisions_795_797. Accessed October 7, 2024. <strong>3.</strong> Data on file. DOF-EH-01. Endo USA, Inc.; July 31, 2023. <strong>4.</strong> U.S. Food & Drug Administration. New Drug Approval for Epinephrine: NDA 215875. April 21, 2023. <strong>5.</strong> Data on file. DOF-DX-01. Endo USA, Inc.; July 26, 2023. <strong>6.</strong> Data on file. DOF-NHC-01. Endo USA, Inc.; November 13, 2024. <strong>7.</strong> Data on file. DOF-PRVD-01. Endo USA, Inc.; July 20, 2023. <strong>8.</strong> Data on file. DOF-VS-02. Endo USA, Inc.; September 20, 2023. <strong>9.</strong> Bivalirudin Injection. Prescribing Information. Endo USA, Inc. <strong>10.</strong> Healthcare Distribution Alliance. Standard pharmaceutical product and medical device information: Bivalirudin Injection. July 10, 2023.
        </p>
    </>
)

function EphedrineSulfateInjectionPage({ pageContext }) {

    return (
        <Layout
            product="eph"
            pageClass="products page-ephedrine"
            reference={Reference}
            breadcrumbData={pageContext.breadcrumb}
        >
            <Seo pageTitle="Ephedrine Sulfate Injection, USP" pageDescription="Ephedrine Sulfate Injection ready-to-use vials" />

            <section className="first">
                <div className="centered-content">
                    <ProductHero
                        productTitle="Ephedrine Sulfate Injection"
                        productSubTitle="Available in ready-to-use <br class='show-desktop' />(RTU) vials"
                        productUnitsOne="50 mg/10 mL (5 mg/mL)"
                        productImg={imgEphedrineSulfate}
                        productImgAlt="Ephedrine sulfate injection premixed vials"
                        productImgHeight='582'
                        productImgWidth='502'
                        className="prd-ephedrine"
                    ></ProductHero>
                </div>
            </section>

            <section className="gray-bg">
                <div className="centered-content">
                    <Row align="start" justify="between">
                        <Col xl={4.25} lg={5} xs={12}>
                            <SlideContent
                                subTitle="Ephedrine Sulfate Injection Product Details"
                                details="Ephedrine Sulfate Injection RTU vials require no compounding, diluting, or mixing and provide extended dating compared with compounded products.<sup>1-3</sup>* Ephedrine Sulfate Injection RTU vials are preservative-free, latex-free, and compatible with most automated dispensing machines."
                            />
                        </Col>
                        <Col lg={6} xs={12}>
                            <Slides data={productData.ephedrine} />
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="links-row">
                <div className="centered-content">
                    <Row>
                        <Col xs={12}>

                            <a
                                href="https://www.ephedrinepmv.com/workflow-scenarios"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="external-link"
                                style={{ display: 'inline-block' }}>
                                Pharmacy Workflow Preparation
                            </a>

                            <p>
                                <a
                                    href="https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/3560f4fa-2159-45df-8203-58285d13fd12/3560f4fa-2159-45df-8203-58285d13fd12_source__v.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="pdf-link">
                                    Ephedrine Information Document
                                </a>
                            </p>
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="cta-row">
                <div className="centered-content">
                    <div className="block-title">
                        <h3>Explore TruDelivery<sup>&reg;</sup> RTU portfolio</h3>
                    </div>

                    <AllProducts />
                </div>
            </section>

            <section className="block-footnotes">
                <div className="centered-content">
                    <div className="footnotes">
                        <div className="footnotes-wrap">
                            <span className='symbol'>*</span>
                            <p className="footnotes-symbols">For Category 3 compounded sterile preparations (CSP), the maximum beyond-use date (BUD) is 90 days at controlled room temperature (CRT), 120 days in refrigerator, and 180 days in freezer<sup>2</sup> versus shelf life of 2 years at CRT for Adrenalin<sup>&reg;</sup>, Dexmedetomidine, Ephedrine, Naloxone, and PREVDUO<sup>®</sup><sup>3-7</sup>; 12 months at CRT and 2 years in refrigerator for Vasostrict<sup>®8</sup>; 72 hours at CRT and 18 months in refrigerator for Bivalirudin.<sup>9,10</sup></p>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default EphedrineSulfateInjectionPage;