import React from "react";
import { Layout, Seo } from 'components';
import { Row, Col } from "react-grid-system";
import ProductHero from "components/product-hero";
import SlideContent from "components/slide-content";
import productData from "components/product-data";
import Slides from "components/product-data/slides";
import imgNaloxone from "images/products/img-naloxone-hcl-injection.webp";
import './products.scss';
import AllProducts from "../../codes/components/all-products/indexs";

const Reference = (
  <>
    <p>
      <strong>References: 1.</strong> Naloxone Hydrochloride Injection. Prescribing Information. Endo USA, Inc. <strong>2.</strong> The United States Pharmacopeial Convention. USP compounding standards and beyond-use dates. https://go.usp.org/2022_Revisions_795_797. Accessed October 10, 2024. <strong>3.</strong> Data on file. DOF-NHC-01. Endo USA, Inc.; November 13, 2024. <strong>4.</strong> U.S. Food & Drug Administration. New Drug Approval for Epinephrine: NDA 215875. April 24, 2023. <strong>5.</strong> Data on file. DOF-DX-01. Endo USA, Inc.; July 27, 2023. <strong>6.</strong> Data on file. DOF-EH-01. Endo USA, Inc.; July 31, 2023. <strong>7.</strong> Data on file. DOF-PRVD-01. Endo USA, Inc.; July 20, 2023. <strong>8.</strong> Data on file. DOF-VS-02. Endo USA, Inc.; September 21, 2023. <strong>9.</strong> Bivalirudin Injection. Prescribing Information. Endo USA, Inc. <strong>10.</strong> Healthcare Distribution Alliance. Standard pharmaceutical product and medical device information: Bivalirudin Injection. July 10, 2023.
    </p>
  </>
)

function NaloxonePage({ pageContext }) {

  return (
    <Layout
      product="naloxone"
      pageClass="products page-naloxone"
      reference={Reference}
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo pageTitle="Naloxone Hydrochloride Injection, USP" pageDescription="Naloxone Hydrochloride Injection carton and prefilled syringe" />

      <section className="first">
        <div className="centered-content">
          <ProductHero
            productTitle="Naloxone Hydrochloride Injection, USP"
            productSubTitle="Available in prefilled syringes "
            productUnitsOne="2 mg/2 mL (1 mg/mL)"
            productImg={imgNaloxone}
            productImgAlt='Naloxone Hydrochloride Injection carton and prefilled syringe'
            productImgHeight='630'
            productImgWidth='626'
            className="prd-naloxone"
          ></ProductHero>
        </div>
      </section>

      <section className="gray-bg">
        <div className="centered-content">
          <Row align="start" justify="between">
            <Col lg={5} xs={12}>
              <SlideContent
                subTitle="<strong>Naloxone Hydrochloride Injection Product Details</strong>"
                details="Naloxone Hydrochloride Injection prefilled syringes do not require compounding, diluting, mixing, or transferring and provide extended dating compared with compounded products.<sup>1-3</sup>* Naloxone Hydrochloride Injection prefilled syringes are preservative-free, latex-free, and compatible with most automated dispensing machines." />
            </Col>
            <Col lg={6} xs={12}>
              <Slides
                data={productData.naloxone}
                tableFootnote={`M&D: Morris & Dickson; NDC: National Drug Code.`}
              />
            </Col>
          </Row>
        </div>
      </section>

      <section className="links-row">
        <div className="centered-content">
          <Row>
            <Col xs={12}>
              <a
                href="https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/dbeb4d51-4e89-417b-ba3b-e102ec44379e/dbeb4d51-4e89-417b-ba3b-e102ec44379e_source__v.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className="pdf-link"
                style={{ display: 'inline-block' }}>
                Naloxone Hydrochloride Injection Sell Sheet
              </a>
            </Col>
          </Row>
        </div>
      </section>

      <section className="cta-row">
        <div className="centered-content">
          <div className="block-title">
            <h3>Explore TruDelivery<sup>&reg;</sup> RTU portfolio</h3>
          </div>

          <AllProducts />

        </div>
      </section>

      <section className="block-footnotes">
        <div className="centered-content">
          <div className="footnotes">
            <div className="footnotes-wrap">
              <span className='symbol'>*</span>
              <p className="footnotes-symbols">For Category 3 compounded sterile preparations (CSP), the maximum beyond-use date (BUD) is 90 days at controlled room temperature (CRT), 120 days in refrigerator, and 180 days in freezer<sup>2</sup> versus shelf life of 2 years at CRT for Adrenalin<sup>&reg;</sup>, Dexmedetomidine, Ephedrine, Naloxone, and PREVDUO<sup>®3-7</sup>; 12 months at CRT and 2 years in refrigerator for Vasostrict<sup>®8</sup>; 72 hours at CRT and 18 months in refrigerator for Bivalirudin.<sup>9,10</sup></p>
            </div>
          </div>
        </div>
      </section>

    </Layout>
  );
}

export default NaloxonePage;