exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tru-delivery-js": () => import("./../../../src/pages/about-tru-delivery.js" /* webpackChunkName: "component---src-pages-about-tru-delivery-js" */),
  "component---src-pages-for-hospital-pharmacists-js": () => import("./../../../src/pages/for-hospital-pharmacists.js" /* webpackChunkName: "component---src-pages-for-hospital-pharmacists-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-adrenalin-injection-js": () => import("./../../../src/pages/products/adrenalin-injection.js" /* webpackChunkName: "component---src-pages-products-adrenalin-injection-js" */),
  "component---src-pages-products-bivalirudin-injection-js": () => import("./../../../src/pages/products/bivalirudin-injection.js" /* webpackChunkName: "component---src-pages-products-bivalirudin-injection-js" */),
  "component---src-pages-products-dexmedetomidine-hcl-js": () => import("./../../../src/pages/products/dexmedetomidine-hcl.js" /* webpackChunkName: "component---src-pages-products-dexmedetomidine-hcl-js" */),
  "component---src-pages-products-ephedrine-sulfate-injection-js": () => import("./../../../src/pages/products/ephedrine-sulfate-injection.js" /* webpackChunkName: "component---src-pages-products-ephedrine-sulfate-injection-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-naloxone-hydrochloride-injection-js": () => import("./../../../src/pages/products/naloxone-hydrochloride-injection.js" /* webpackChunkName: "component---src-pages-products-naloxone-hydrochloride-injection-js" */),
  "component---src-pages-products-prevduo-injection-js": () => import("./../../../src/pages/products/prevduo-injection.js" /* webpackChunkName: "component---src-pages-products-prevduo-injection-js" */),
  "component---src-pages-products-vasostrict-injection-js": () => import("./../../../src/pages/products/vasostrict-injection.js" /* webpackChunkName: "component---src-pages-products-vasostrict-injection-js" */)
}

