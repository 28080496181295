import React, { useState, useEffect, useLayoutEffect } from 'react';
import appConfigs from '../../../app.settings';
import { CookiesProvider, useCookies } from 'react-cookie';
import IntroAnimation from '../components/IntroAnimation';

export const appContext = React.createContext();

const AppProvider = props => {
  // Existing state
  const [headerHeight, setHeaderHeight] = useState(0);
  const [currentIndication, setCurrentIndication] = useState('');
  const [magicshot, setMagicshot] = useState(false);
  const [topOffset, setTopOffset] = useState(0);
  const [navOpen, setNavOpen] = useState(false);
  const [cookies, setCookie] = useCookies(["hasSeenAnimation"]);
  const [showAnimation, setShowAnimation] = useState(false);

  const handleAnimationCompleted = () => {
    setCookie('hasSeenAnimation', "true");
    setShowAnimation(false);
  }

  useLayoutEffect(() => {
    setShowAnimation(!cookies["hasSeenAnimation"]);
  }, [cookies])

  useEffect(() => {
    if (showAnimation) {
      setTimeout(() => {
        document.body.classList.add('visible')
      }, 200);
    } else {
      document.body.classList.add('visible')
    }
  }, [showAnimation])

  // Existing functions
  const updateHeaderHeight = _height => {
    setHeaderHeight(_height);
  };

  const updateCurrentIndication = _indication => {
    setCurrentIndication(_indication);
  };

  const updateMagicshot = _flag => {
    setMagicshot(_flag);
  };
  // Existing useEffect
  useEffect(() => {
    appConfigs.siteOptions.HEADER_TYPE === 'fixed'
      ? setTopOffset(headerHeight)
      : setTopOffset(0);
  }, [headerHeight]);

  return (
    <appContext.Provider
      value={{
        appConfigs,
        currentIndication,
        headerHeight,
        magicshot,
        topOffset,
        updateHeaderHeight,
        updateCurrentIndication,
        updateMagicshot,
        navOpen,
        setNavOpen
      }}
    >
      {showAnimation && <IntroAnimation onComplete={handleAnimationCompleted} />}
      {props.children}
    </appContext.Provider>
  );
};

export default ({ element }) => (
  <AppProvider>
    <CookiesProvider>{element}</CookiesProvider>
  </AppProvider>
);